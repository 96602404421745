<template>
  <v-dialog
    id="form_view"
    v-if="bActive"
    overlay-color="primary"
    overlay-opacity=".97"
    persistent
    :fullscreen="smUp ? false: true"
    max-width="60%"
    v-model="bActive">
    <v-card
      :shaped="smUp"
      class="dialog_card_container">
      <!-- Start close icon -->
      <v-layout
        row
        justify-end>
        <v-btn
          style="margin-right: 10px; margin-top: 20px"
          icon
          fab
          small
          color="grey"
          text
          @click="close">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-layout>
      <!-- End close icon -->
      <!-- Start dialog title -->
      <v-container
        style="margin-bottom: 20px">
        <v-layout
          row
          justify-center>
          <p
            v-text="dialog_title"
            class="dialog_title_text"
          />
        </v-layout>
      </v-container>
      <!-- End dialog title -->
      <!-- Start form containr -->
      <v-container>
        <v-layout
          row
          justify-center>
          <v-layout
            column
            :align-center="!smUp"
            style="max-width: 80%">
            <!-- Start notifications table -->
            <div
              v-if="!bLoading">
              <v-layout
                style="width: 100%;"
                row
                justify-center>
                <notifications-table 
                  :items="notifications_list"
                  @update="get_notifications"
                />
              </v-layout>
              <v-pagination 
                style="margin-top: 40px; margin-bottom: 10px"
                v-model="current_page" 
                :length="num_pages">
              </v-pagination>
            </div>
            <!-- End notifictions table -->
          <!-- Loading animation -->
          <v-card
            flat
            style="height: 100%; width: 100%; background-color: transparent;"
            v-else>
            <v-layout
              :style="smUp ? 'max-width: 90%; margin-top: 10%':''"
              row
              justify-center>
              <v-progress-circular
                indeterminate
                rotate
                color="primary"
                size="100"
                width="1"
              />
            </v-layout>
          </v-card>
          <!-- Loading animation -->
          </v-layout>
        </v-layout>
      </v-container>
      <!-- End form containr -->
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    current_page: 1,
    num_pages: 0,
    bLoading: false,
    dialog_title: 'Notification History',
    notifications_list: [],
  }),
  props: {
    bActive: Boolean,
    outage_id: String
  },
  mounted () {
    this.get_notifications()
  },
  watch: {
    bActive: function () {
      if (this.bActive) {
        this.get_notifications()
      }
    },
    current_page: function () {
      this.get_notifications()
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    get_notifications () {
      if (this.bActive) {
        this.bLoading = true
        db.get(`${uri}/v2/api/notifications/` + this.outage_id, {
          headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Authorization': this.usr_token
          },
          params: {
            iPage: this.current_page,
            iPageSize: 15
          }
        })
        .then(resp => {
          this.bLoading = false
          this.$store.commit('toggle_alert', {color: 'success', text: resp.data.message})
          this.num_pages = resp.data.iTotalPages
          this.notifications_list = resp.data.notifications
        })
        .catch(err => {
          this.bLoading = false
          this.$store.commit('toggle_alert', {color: 'fail', text: err.response.data.message})
        })
      }
    }
  },
  components: {
    NotificationsTable: () => import('@/components/sections/Admin/Notifications/NotificationsTable')
  },
  computed: {
    usr_token () {
      return 'Bearer ' + this.$store.state.token
    },
    smUp () {
      return this.$vuetify.breakpoint.smAndUp
    }
  }
}
</script>
<style scoped>
  .field_texts {
    margin-bottom: -25px;
    font-family: 'Open sans', sans-serif;
    color: 'black';
    font-size: 12px;
    letter-spacing: .1px;
  }
  .password_layout {
    width: 100%; 
    margin-bottom: 10px
  }
  .dialog_card_container {
    padding: 0%
  }
</style>